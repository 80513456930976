<template>
  <div class="tab-pane active">
    <div class="responsive-table">
      <div class="loading min-h-300" v-if="loading">
        <LoadingAnim />
      </div>
      <table v-else class="table table-borderless">
        <thead class="">
          <tr>
            <th scope="col">{{ $tc('app.name', 1) }}</th>
            <!-- Data -->
            <th scope="col">{{ $t('app.date') }}</th>
            <!-- Remetente -->
            <th scope="col">Status</th>
            <!-- Destinatario -->
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="file in files" :key="file.id" :to="`history/${file.id}`">
            <td data-label="Data">
              <div>
                <router-link :to="`history/${file.id}`">
                  {{ file.name }}
                </router-link>
              </div>
            </td>
            <td data-label="Tipo">
              {{ file.created_at }}
            </td>
            <td data-label="Status">
              <span
                v-if="file.status == 'queued'"
                class="badge badge-pill badge-light"
                >{{ $t('generic-str.status.lbl-queue') }}</span
              >
              <span
                v-if="file.status == 'in_progress'"
                class="badge badge-pill badge-warning"
                >Em Processo</span
              >
              <span
                v-if="file.status == 'failed'"
                class="badge badge-pill badge-danger"
                >{{ $t('generic-str.status.lbl-failure') }}</span
              >
              <span
                v-else-if="file.status == 'done'"
                class="badge badge-pill badge-success"
                >{{ $t('generic-str.status.lbl-exported') }}</span
              >
            </td>
            <td data-label="Destinatário">
              <custom-button
                v-if="file.status == 'done'"
                class="btn btn-success btn-sm"
                :loading="file.downloading"
                @click="exportFileSMS(file)"
              >
                <i class="fas fa-download color-white"></i>
              </custom-button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <pagination :lastPage="pages" @change="fetch" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SmsService from '@/services/sms.service';
import Pagination from '@/components/Pagination.vue';
import CustomButton from '@/components/CustomButton.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

export default {
  name: 'SmsHistoryTab',
  components: {
    // PageHeader,
    Pagination,
    LoadingAnim,
    CustomButton,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: true,
      files: [],
      form: {
        id: '',
        status: '',
        from: '',
        to: '',
        created_at: {
          gte: '',
          lte: '',
        },
        order_by: ['scheduled_at', 'desc'],
        page: 1,
      },
      pages: 1,
      exporting: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.files = [];
      this.loading = true;

      this.$store
        .dispatch('fetchSmsReports', {
          filter: {
            ...this.filters,
            created_at: {
              gte: this.startDate,
              lt: this.endDate,
            },
          },
          page,
        })
        .then((response) => {
          response.data.forEach((file) => {
            file.downloading = false;
            this.files.push(file);
          });
          this.pages = response.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exportData(format) {
      this.$store.dispatch('exportSmsMessages', {
        filter: this.filters,
        export: format,
      });
    },
    exportMessages() {
      this.exporting = true;
      SmsService.exportSms({
        filter: {
          id: this.form.id,
          from: this.form.from,
          to: this.form.to,
          status: this.form.status,
          sent_at: {
            from: this.beginDate,
            to: this.endDate,
          },
        },
      })
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.toast.report'),
              type: 'success',
            });
            this.$router.push('/sms/exports');
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.exporting = false;
        });
    },
    exportFileSMS(file) {
      file.downloading = true;
      this.$store
        .dispatch('downloadSmsReport', file.id)
        .then(
          (response) => {
            this.fileExport(response, file.name);
          },
          (error) => {
            this.content = error;
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          file.downloading = false;
        });
    },
    fileExport(response, name) {
      if (response.headers['content-type'] === 'application/zip') {
        name = `${name}.zip`;
      } else {
        name = `${name}.csv`;
      }
      const data = response.data;
      const blob = new Blob([data], { type: response.headers['content-type'] });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, name);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', name);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}

/* Rebranding */
.rebranding .card.borderless {
  box-shadow: unset !important;
}

.form-group {
  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.icon-success {
  color: #6eda2c;
}
.icon-failed {
  color: #ff0000;
}
</style>
